import detect from "./detect";
import mobileDetect from "./mobileDetect";
import deviceDetect from "./deviceDetect";

const md = new mobileDetect();
const newDetectDevice = {
  ...detect,
  orientation: deviceDetect.orientation,
  type: deviceDetect.type,
  deviceName: md.phone() ? md.phone() : md.tablet() ? md.tablet() : "Desktop",
  mdOS: md.os(),
  isConsole: md.match('playstation|xbox'),
}

delete newDetectDevice.bit;
delete newDetectDevice.mobile;
newDetectDevice.isPhone = deviceDetect.type === "mobile";
newDetectDevice.isTab = deviceDetect.type === "tablet";
newDetectDevice.isPortrait = deviceDetect.orientation === "portrait";
newDetectDevice.isLandScape = deviceDetect.orientation === "landscape";

export default newDetectDevice;