import React from 'react';
import { Grid, Typography, IconButton } from "core/components";
import { withStyles }  from "core/components/Styles";
import plusIcon from "assets/images/icons/plus.png";
import minusIcon from "assets/images/icons/minus.png";
const styles = () => ({
  root:{
    background:"#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.128223)",
    border: "1px solid #DBDBDB",
    borderRadius: 8,
    width:196
  },
  iconAndTextCont:{
    padding: "20px 10px 10px 10px",
  },
  iconCont:{
    height: 59,
    display:"inline-flex"
  },
  btnsCont:{
    border: "1px solid #E0E0E0",
    padding: "0px 10px"
  },
  count:{
    fontSize:28,
    color:"#242A37"
  },
  iconBtn:{
    width:26,
    height:26,
    background: "#EFEFEF",
    padding: "4px 4px 3px 3px"
  }
})
const Service = (props) => {
  const { 
      classes, 
      icon:Icon, 
      title,
      count,
      onAdd,
      onDelete } = props;
  return (
    <Grid className={classes.root}>
      <Grid 
        container 
        direction="column" 
        alignContent="center" 
        alignItems="center"
        justify="center"
        className={classes.iconAndTextCont}
      >
        <Grid className={classes.iconCont}>
          {Icon && <Icon />}
        </Grid>
        <Typography align="center">
          {title}
        </Typography>
      </Grid>

      <Grid 
        alignItems="center"
        justify="space-between"
        container 
        className={classes.btnsCont}
      >
        <IconButton size="small" className={classes.iconBtn} onClick={onDelete}>
          <img src={minusIcon} alt="minus" />
        </IconButton>
        <Typography className={classes.count}>
          {count}
        </Typography>
        <IconButton size="small" className={classes.iconBtn} onClick={onAdd}>
          <img src={plusIcon} alt="plus" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(Service);

