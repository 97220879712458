import React from "react";
import withStyles from "react-jss";

const CheckBoxField = ({classes, id, input, label})=>{

  return (
    <label htmlFor={id} className={classes.wrapper}>
      <input
        {...input}
        type="checkbox"
        id={id}
        className={classes.input}
      />
      <span className={classes.label}>{label}</span>
    </label>
  );
};


const styles = {
  wrapper: {
    display: "flex",
    alignItems: "start"
  },
  input:{
    marginRight : 8
  },
  label: {
    lineHeight: "normal"
  }
};


export default withStyles(styles)(CheckBoxField);