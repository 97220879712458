import { SET_MSG_AND_SHOW, RESET_MSG } from "../types";


export const setMsgAndShow = (msg) => ({
  type: SET_MSG_AND_SHOW,
  payload: msg
})


export const resetMsg = () => ({
    type: RESET_MSG
  })
