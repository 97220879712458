function isNumberKey(e) {
  var result = false;
  try {
    var charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) {
      result = true;
    }
  } catch (err) {
    //console.log(err);
  }
  return result;
}



export default isNumberKey;