import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar';
import randomColor from 'randomcolor';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  default: {
    // display: 'inline-flex',
    fontWeight: 'bold',
    boxSizing: 'content-box',
    "&.avatar-overlap:not(:first-child)": {
      marginLeft: -15,
      border: "2px solid"
    }
  }
}

class UserAvatar extends Component {
  render() {
    const {classes, className = "", user = {}, style = {}, overlap, size, ...other} = this.props;
    const avatarClass = overlap ? `${classes.default} ${className} avatar-overlap` : `${classes.default} ${className}`;
    style.backgroundColor = randomColor( { seed: user.email, luminosity: 'dark' } );
   
    if(size) {
      style.fontSize = size / 2;
      style.width = size;
      style.height = size;
    }

    return (
        <Avatar {...other} alt={user.initials} src={user.img} className={avatarClass} style={style}>
          { !user.img && 
              user.initials 
          }
        </Avatar>
    )
  }
}
export default withStyles(styles)(UserAvatar);