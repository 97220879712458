import React from "react";
import Button from "core/components/Button";
import { withStyles } from "core/components/Styles";
// import { Plus } from "mdi-material-ui";

import Icon from "core/components/Icon";

const styles = () => ({
  fabBtn:{
    position:"fixed",
    right: 20,
    bottom: 20,
    zIndex: 99,
    height:40,
    width: 40,
    background:"#ed5454",
    color: "#fff",

    minWidth: 0,
    borderRadius:34,

    "&:hover":{
      background:"#ed5454",
      boxShadow:"0px 3px 5px -1px rgba(0, 0, 0, 0.4), 0px 6px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 18px 0px rgba(0, 0, 0, 0.15)"
    }
  },
  addIcon:{
    height:12,
    width:12,
 
  },
})
const FabButton = (props) => {
  const { classes,classNames, onClick, icon, iconName } = props;
  return (
    <Button variant="contained" color="primary" aria-label="Add" onClick={onClick} className={classes.fabBtn} id="addJobBtn" classes={classNames}>
      {icon 
        ? icon 
        : <Icon lineHeight="inherit" style={{ height: 18, width: 18 }}>{ iconName || "add" }</Icon>
      } 
    </Button>
  )
};

export default withStyles(styles)(FabButton);
