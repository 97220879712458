import React, { Component } from "react";
import cn from "classnames";
import {primaryColor, primaryGradient, secondaryColor, secondaryGradient } from "utils/colors";
import withStyles from "react-jss";
const styles = {
  root: {},
  primary: {
    color: primaryColor
  },
  primaryGradient: {
    background: primaryGradient,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  },
  secondary: {
    color: secondaryColor
  },
  secondaryGradient: {
    // color: secondaryGradient
    background: secondaryGradient,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  },
  inherit: {
    lineHeight: 'inherit'
  }
}
class Icon extends Component {
  render() {
    const { className = '', classes, color, children, fontSize, style, variant, lineHeight } = this.props;
    let iconColor = color;
    const styles = {...style};
    if(iconColor && !classes[iconColor]) {
      iconColor = null; 
      styles.color = color;
    }
    if(fontSize) styles.fontSize = fontSize;
    const Tag = variant ? variant : "span";
    return (
      <Tag className={cn("zicon", classes.root, `icon-${children}`, className, { [classes[color]]: iconColor, [classes.inherit]: lineHeight === 'inherit' })} style={styles}></Tag>
    )
  }
}
export default  withStyles(styles)(Icon); 