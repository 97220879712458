import React from "react";
import Button from "core/components/Button";
import Dialog from 'core/components/Dialog';
import DialogActions from 'core/components/DialogActions';
import DialogContent from 'core/components/DialogContent';
import { withStyles } from "core/components/Styles";
import {SliderField} from "../FormField";
import {
  primaryColor, 
  primaryHoverColor, 
  // primaryGradient, 
  secondaryGradient, 
  secondaryColor
} from "../../utils/colors";

const styles = {
  modal: {
    maxWidth: 500,
    margin: ".5rem auto",
    "& .modal-content": {
      borderRadius: 15
    }
  }, 
  modalBody: {
    padding: "20px 1rem 20px",
    borderBottom: "1px solid #d8d8d8"
  },
  modalFooter: {
    justifyContent: "stretch",
    border: "none"
  },
  showTime: {
    textAlign: "center"
  },
  time: {
    fontSize: "2.5em",
    background: secondaryGradient,
    color: "#fff",
    display: "inline-flex",
    padding: "0px .25em",
    margin: "0 .15em",
    borderRadius: "50%",
    height: 80,
    width: 80,
    justifyContent: "center",
    alignItems: "center"
  },
  separator: {
    fontSize: "2.5em",
    color: secondaryColor,
  },
  sliders: {
    paddingTop: 20
  },
  timeText : {
    position: "relative",
    left: "-10",
    fontSize: "15",
    color: secondaryColor,
    marginTop: "7",
    marginBottom: "10"
  },
  slider: {
    padding: 7
  },
  btn: {
    borderRadius: "100px",
    flex: 1
  },
  btnDefault: {
    background: "transparent",
    color:"#000"
  },
  primaryButton: {
    background: primaryColor,
    border: "none",
    flex: "1 1",
    "&:hover": {
      background: primaryHoverColor
    }
  },
  btns: {
    flex: "1 1"
  }
};

const getValue = function (value, type) {
  if(type === "hrs" && value > 12) value -= 12;
  if(value < 10) value = "0"+ value;
  return value;
};

const TimePickerModal = ({isOpen, toggle, handleClose, classes, handleTime, hrs, minutes}) => {
  return (
    <Dialog open={isOpen} onClose={toggle} className={classes.modal}>
      <DialogContent className={classes.modalBody}>
        <div className={classes.showTime}>
          <span className={classes.time}>{getValue(hrs, "hrs")}</span>
          <span className={classes.separator}>:</span>
          <span className={classes.time}>{getValue(minutes)}</span>
          <span className={classes.separator}>{ hrs > 11 ?  "PM" : "AM" }</span>
        </div>
        <div className={classes.sliders}>
          <div className={classes.timeText}>Hours:</div>
          <div className={classes.slider}>
            <SliderField onChange={handleTime("hrs")} max={23} defaultValue={hrs} value={hrs}/>
          </div>
          <div className={classes.timeText}>Minutes:</div>
          <div className={classes.slider}>
            <SliderField onChange={handleTime("minutes")} max={59} defaultValue={minutes} value={minutes}/>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button className={classes.btns} color="primary" variant="contained" onClick={toggle}>Save</Button>{" "}
        <Button className={classes.btns} color="secondary" onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(TimePickerModal);
