import { SET_CURRENT_TODO } from "../types";
import _ from "lodash";


const defaultState = {
  currTodo: null
}


const reducer = (state = defaultState, action) => {
  let finalState = {}
  switch(action.type){
    case SET_CURRENT_TODO:
    finalState = _.cloneDeep(state);
    finalState.currTodo = action.payload
    break
    default:
    finalState = state
  }

  return finalState
}

export default reducer;